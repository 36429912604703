<template>
  <div id="app" class="container-fluid m-0 p-0">
    <!-- HEADER -->
    <nav class="navbar navbar-expand-md navbar-light bg-light p-0 px-2">
      <router-link to="/" class="navbar-brand nav-link">
        <img
          src="favicon.png"
          width="30"
          height="30"
          class="d-inline-block align-top"
          alt=""
        />
        Coronavirus3D
        <span class="sr-only">(home)</span>
      </router-link>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarToggler1"
        aria-controls="navbarToggler1"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarToggler1">
        <ul class="navbar-nav mr-auto"></ul>
        <ul class="navbar-nav">
          <span class="navbar-text text-center">
            <a
              href="http://godziklab.org/"
              target="_blank"
              title="Godzik Lab"
              class="side-margin"
            >
              <img
                src="images/lab_logo.png"
                alt="Godzik Lab logo"
                height="30"
              />
            </a>
            <a
              href="http://csbid.org/"
              target="_blank"
              title="Center for Structural Biology of Infectious Diseases"
              class="side-margin"
            >
            <img src="images/csbid.png"
            alt="Center for Structural Biology of Infectious Diseases" height="30" />
            </a>
            <a
              href="https://www.niaid.nih.gov/research/sars-cov-2-assessment-viral-evolution-program"
              target="_blank"
              title="SAVE"
              class="side-margin"
            >
              <img src="images/save_logo.png" alt="SAVE logo" height="30" />
            </a>
            <a
              href="https://medschool.ucr.edu/"
              target="_blank"
              title="University of California, Riverside School of Medicine"
              class="side-margin"
            >
              <img src="images/ucr3.png" alt="UCR logo" height="30" />
            </a>
            <a
              href="http://www.gisaid.org/"
              target="_blank"
              class="small ml-4"
              title="GISAID Initiative"
              >Enabled by data from
              <img src="images/gisaid.png" alt="GISAID logo" height="26" />
            </a>
          </span>
        </ul>
      </div>
    </nav>
    <nav class="navbar sticky-top navbar-expand-md navbar-dark bg-dark">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarToggler"
        aria-controls="navbarToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarToggler">
        <ul class="navbar-nav mr-auto mt-1 mt-lg-0">
          <li class="nav-item">
            <router-link to="/lineageTracking/v1/*" class="nav-link">
              Variant tracking viewer
              <span class="sr-only">(Variant tracking viewer)</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/cv" class="nav-link">
              Lineages/Mutations
              <span class="sr-only">(Lineages/Mutations)</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/3dproteomeviewer" class="nav-link">
              3D proteome viewer
              <span class="sr-only">(3D proteome viewer)</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/drug" class="nav-link">
              Drug resistance
              <span class="sr-only">(Drug resistance)</span>
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownHelpLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              About
            </a>
            <div
              class="dropdown-menu bg-dark navbar-dark"
              aria-labelledby="navbarDropdownHelpLink"
            >
              <router-link to="/help" class="nav-link">
                Help
                <span class="sr-only">(help)</span>
              </router-link>
              <router-link to="/examples" class="nav-link">
                Examples
                <span class="sr-only">(examples)</span>
              </router-link>
              <router-link to="/acknowledgements" class="nav-link">
                Acknowledgements
                <span class="sr-only">(acknowledgements)</span>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <!-- CONTENT -->
    <div class="main no-padding">
      <hr />
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  data: function () {
    return {};
  },
};
</script>
<style>
.no-padding {
  padding: 0px !important;
}
.nav-link {
  padding-left: 0px;
  padding-right: 8px;
}

.navbar-dark > .router-link-active {
  color: white;
}
</style>