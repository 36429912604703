import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "mutesxplorer" */ '../views/MutationsExplorer.vue')
    // component: () => import(/* webpackChunkName: "lineagetracking" */ '../views/LineageTracking.vue')
  },
  {
    path: '/analysis',
    name: 'Analysis',
    component: () => import(/* webpackChunkName: "analysis" */ '../views/Analysis.vue')
  },
  {
    path: '/3dproteomeviewer',
    name: '3DProteomeViewer',
    component: () => import(/* webpackChunkName: "proteomeviewer" */ '../views/Home.vue')
  },
  {
    path: '/lineagetracking/:v/:file',
    name: 'LineageTracking',
    component: () => import(/* webpackChunkName: "lineagetracking" */ '../views/LineageTracking.vue')
  },
  {
    path: '/mutsexplorer',
    redirect:'/drug',
    name: 'MutationsExplorer',
    component: () => import(/* webpackChunkName: "mutesxplorer" */ '../views/MutationsExplorer.vue')
  },
  {
    path: '/drug',
    name: 'MutationsExplorer',
    component: () => import(/* webpackChunkName: "mutesxplorer" */ '../views/MutationsExplorer.vue')
  },
  {
    path: '/help',
    name: 'Help',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
  },
  {
    path: '/examples',
    name: 'Examples',
    component: () => import(/* webpackChunkName: "examples" */ '../views/Examples.vue')
  },
  {
    path: '/acknowledgements',
    name: 'Acknowledgements',
    component: () => import(/* webpackChunkName: "acknowledgement" */ '../views/Acknowledgement.vue')
  },
  {
    path: '/cv',
    name: 'CombinationsViewer',
    component: () => import(/* webpackChunkName: "lineagetracking" */ '../views/CombinationsViewer.vue')
  },
]

const router = new VueRouter({
  routes: routes, 
  base: "/",
  // mode:'history'
})

export default router
